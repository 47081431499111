<template>
  <div class="about">
    <div class="c-thank-you">
      <img alt="Vue logo" class="u-p c-logo" src="@/assets/logo.png">
      <div class="u-text-center u-pt-x2 Poleno _ExtraBold" style="font-size: 30px">
        Thanks for subscribing!
      </div>
      <div class="u-pt-x2 u-pb-x8 u-text-center" style="font-size: 12px">
        Check your email for confirmation.
      </div>
      <div class="u-pt-x2 u-pb-x2 u-text-center" style="font-size: 12px">
        Now go follow QUENCH on social media.
      </div>
    </div>
    <q-footer />

  </div>
</template>
<script>
import QFooter from '@/components/QFooter.vue'
export default {
  name: 'homepage',
  components: {
    QFooter
  }
}
</script>
